import React, { Component } from 'react';
import { Route, Switch } from 'react-router';

import AnonymousRoute from './App/Layouts/AnonymousRoute';
import AuthorizeRoute from './App/Layouts/AuthorizeRoute';
import DashboardLayout from './App/Layouts/DashboardLayout';
import DefaultLayout from './App/Layouts/DefaultLayout/DefaultLayout';
import "./css/style.css";

import ReactGA from 'react-ga';


import Cookies from 'universal-cookie';


const cookies = new Cookies();
const TRACKING_ID = "UA-228664458-1";
ReactGA.initialize(TRACKING_ID);
export default class App extends Component {
    static displayName = App.name;

    constructor(props) {
        super(props);
        this.state = {

        };
    }


    componentDidMount() {

        //hotjar code (Heatmap tools)
        (function (h, o, t, j, a, r)
        {
            h.hj = h.hj || function ()
            { (h.hj.q = h.hj.q || []).push(arguments) };
            h._hjSettings = { hjid: 3819819, hjsv: 6 };
            a = o.getElementsByTagName('head')[0];
            r = o.createElement('script');
            r.async = 1;
            r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
            a.appendChild(r);
        })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');

        if (cookies.get("insubeeToken") !== undefined) {

        }
    }



    render() {
        return (
            <Switch>
                {/*<Route exact path='/' component={DefaultLayout} />*/}

                {/*<Route path='/counter' component={Counter} />*/}
                <AuthorizeRoute path='/admin' name="DashboardLayout" component={DashboardLayout} />

                <AnonymousRoute path="/" component={DefaultLayout} />
                {/*<Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />*/}
               
            </Switch>
        );
    }
}
